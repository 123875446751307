import React from "react"
import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"

import legendsContent from "./legendsContent"

import styles from "./calendar.module.scss"

const CalendarLegends = () => {
  return (
    <div className={classNames(styles["calendar__legends"])}>
      <div className={classNames(styles["calendar__legendsHeader"])}>
        <h1>Legends</h1>
      </div>
      <div className={classNames(styles["calendar__legendsBody"])}>
        {legendsContent.map((section) => {
          switch (section?.type) {
            case "color":
              return (
                <div
                  className={classNames(
                    styles["calendar__legendsBody__section"]
                  )}
                >
                  <h2>{section?.header}</h2>
                  {section?.items.map((item) => {
                    return (
                      <div
                        className={classNames(
                          styles["calendar__legendsBody__sectionRow"]
                        )}
                      >
                        <div
                          className={classNames(
                            styles[
                              `calendar__legendsBody__sectionRow__${item.key}`
                            ]
                          )}
                        >
                          {" "}
                        </div>
                        <p className="ml-1">{item.label}</p>
                      </div>
                    )
                  })}
                </div>
              )

            // case "text":
            //   return (
            //     <div
            //       className={classNames(
            //         styles["calendar__legendsBody__section"]
            //       )}
            //     >
            //       <h2>{section?.header}</h2>
            //       {section?.items.map((item) => {
            //         return (
            //           <div
            //             className={classNames(
            //               styles["calendar__legendsBody__sectionRow"]
            //             )}
            //           >
            //             <div
            //               className={classNames(
            //                 styles[
            //                   `calendar__legendsBody__sectionRow__${item.key}`
            //                 ]
            //               )}
            //             >
            //               <p>{item?.key}</p>
            //             </div>
            //             <p className="ml-1">{item.label}</p>
            //           </div>
            //         )
            //       })}
            //     </div>
            //   )

            case "icon":
              return (
                <div
                  className={classNames(
                    styles["calendar__legendsBody__section"]
                  )}
                >
                  <h2>{section?.header}</h2>
                  {section?.items.map((item) => {
                    return (
                      <div
                        className={classNames(
                          styles["calendar__legendsBody__sectionRow"]
                        )}
                      >
                        {item?.key === "star" ? (
                          <FontAwesomeIcon icon={faStar} size="xs" />
                        ) : null}

                        <p className="ml-1">{item.label}</p>
                      </div>
                    )
                  })}
                </div>
              )

            default:
              return null
          }
        })}
      </div>
    </div>
  )
}

export default CalendarLegends
