import React from "react"
import classNames from "classnames"

import styles from "./calendar.module.scss"

const MonthEvent = ({ event }) => {
  return (
    <div className={classNames(styles["monthEventContainer"])}>
      <p>
        {event.startTime} {event.clientName}
      </p>
    </div>
  )
}

export default MonthEvent
