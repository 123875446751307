import React from "react"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"

import classNames from "classnames"
import styles from "./calendar.module.scss"

const WeekEvent = ({ event }) => {
  let eventDuration = moment(event.end).diff(event.start)
  let min45 = 2700000
  let hr1min15 = 4500000
  let assessment = event?.consultation?.riskAssessment?.replace(/\s/g, "") || ""

  switch (true) {
    case eventDuration <= min45:
      return (
        <div className={classNames(styles["weekEventContainer"])}>
          <div
            className={classNames(
              styles[`weekEventContainer__shortBody${assessment}`]
            )}
          >
            <div
              className={classNames(styles["weekEventContainer__headerLeft"])}
            >
              {event?.instance === "First Time" || "First-time" ? (
                <FontAwesomeIcon
                  icon={faStar}
                  size="xs"
                  className={classNames(
                    styles["weekEventContainer__headerIcon"]
                  )}
                />
              ) : null}
              {/* <p>{event.clientName}</p> */}
            </div>
            <div
              className={classNames(styles["weekEventContainer__headerRight"])}
            >
              <p className="is-size-8">#{event.consultation.zendeskId}</p>
            </div>
          </div>
        </div>
      )
    case min45 <= eventDuration && eventDuration <= hr1min15:
    default:
      return (
        <div className={classNames(styles["weekEventContainer"])}>
          <div
            className={classNames(
              styles[`weekEventContainer__header${assessment}`]
            )}
          >
            <div
              className={classNames(styles["weekEventContainer__headerLeft"])}
            >
              {event?.instance === "First Time" || "First-time" ? (
                <FontAwesomeIcon
                  icon={faStar}
                  size="xs"
                  className={classNames(
                    styles["weekEventContainer__headerIcon"]
                  )}
                />
              ) : null}
              {/* <p>{event.clientName}</p> */}
            </div>
            <div
              className={classNames(styles["weekEventContainer__headerRight"])}
            >
              <p className="is-size-8">#{event.consultation.zendeskId}</p>
            </div>
          </div>
          <div className={classNames(styles["weekEventContainer__body"])}>
            <p className="is-size-7">{event.clientName}</p>
            {eventDuration > hr1min15 ? <p>{event.instance}</p> : null}
          </div>
        </div>
      )
  }
}

export default WeekEvent
