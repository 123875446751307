import React, { useState, useEffect } from "react"
import moment from "moment"
import { navigate } from "gatsby"
import { Calendar, momentLocalizer } from "react-big-calendar"
import classNames from "classnames"

import Loading from "elements/Loading"
import Message from "elements/Message"
import DashboardLayout from "layout/DashboardLayout"
import MonthEvent from "./utils/MonthEvent"
import WeekEvent from "./utils/WeekEvent"
import DayEvent from "./utils/DayEvent"
import CalendarLegends from "./utils/CalendarLegends"
import { getPatientConsultations } from "../Consultations/ConsultationFormPage/services/getPatientConsultations"

import { getAllDates } from "./services/getAllDates"
import { isBrowser } from "services/general"

import styles from "./utils/calendar.module.scss"

const Schedule = ({ pageContext }) => {
  const [consultations, setConsultations] = useState([])
  const localizer = momentLocalizer(moment)

  let { module } = pageContext

  const getMHPConsultations = async () => {
    let fetchedConsultations = await getAllDates()
    setConsultations(fetchedConsultations)
  }

  // clear selected patient and consultation in session storage
  if (isBrowser()) {
    sessionStorage.setItem("selectedPatient", null)
    sessionStorage.setItem("selectedCalendarConsultation", null)
  }

  useEffect(() => {
    sessionStorage.setItem("activeConsultations", [])
    getMHPConsultations()
  }, [])

  let allEvents = consultations.map((event) => {
    // formatting info for event component

    let startTime = moment(event.start.dateTime).format("h:mm a")
    let clientName =
      event?.attendees?.find(
        (attendee) => attendee.email === event?.consultation?.clientEmail
      )?.displayName || "Attendee"
    let instance = event?.consultation?.consultInstance || "First-time"

    return {
      ...event,
      startTime: startTime,
      clientName: clientName,
      instance: instance,
      start: new Date(event.start.dateTime),
      end: new Date(event.end.dateTime),
      allDay: false,
    }
  })

  return (
    <DashboardLayout
      pageContext={pageContext}
      seoTitle={module.title}
      title={module.title}
    >
      <Message color="primary" align="left">
        This is your consultation schedule. All consultations in this view have
        been confirmed by the patient. Click on the cards to view the patient's
        profile.
      </Message>
      {consultations.length ? (
        <div className={classNames(styles["calendar"])}>
          <Calendar
            localizer={localizer}
            defaultView={"day"}
            defaultDate={new Date()}
            events={allEvents}
            startAccessor="start"
            endAccessor="end"
            showMultiDayTimes={true}
            views={["month", "week", "day"]}
            components={{
              month: { event: MonthEvent },
              week: { event: WeekEvent },
              day: { event: DayEvent },
            }}
            onSelectEvent={async (event) => {
              sessionStorage.setItem(
                "activeConsultations",
                JSON.stringify(
                  await getPatientConsultations({
                    patientId: event.consultation.userId,
                  })
                )
              )

              navigate(
                `/consultations/mh/add?prevPage=/${pageContext?.module?.name.toLowerCase()}&patientId=${
                  event?.consultation?.userId
                }&consultId=${event?.consultation?.id}`
              )
            }}
            messages={{
              next: ">",
              previous: "<",
            }}
            className={classNames(styles["calendar__main"])}
          />
          <CalendarLegends />
        </div>
      ) : (
        <Loading />
      )}
    </DashboardLayout>
  )
}

export default Schedule
